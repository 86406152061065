var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "6" } }, [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "playroomName" }, [
                      _vm._v(_vm._s(_vm.order.playroomName))
                    ]),
                    _vm.order.hiddenAddress
                      ? _c("CIcon", {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                appendToBody: true,
                                content: _vm.$t(
                                  "components.OrderDetailGeneralInformationPart.hiddenAddressTooltip"
                                )
                              },
                              expression:
                                "{\n              appendToBody: true,\n              content: $t('components.OrderDetailGeneralInformationPart.hiddenAddressTooltip'),\n            }"
                            }
                          ],
                          staticClass: "infoIcon",
                          attrs: { name: "cis-info-circle" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.order.hiddenAddress
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.order.playroomStreet) +
                          " " +
                          _vm._s(_vm.order.playroomHouseNumber) +
                          ", " +
                          _vm._s(_vm.order.playroomZipCode) +
                          " " +
                          _vm._s(_vm.order.playroomCity) +
                          ", " +
                          _vm._s(_vm.order.playroomCountry) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.order.ownerName
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.host"
                            )
                          ) +
                          ": " +
                          _vm._s(_vm.order.ownerName) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.order.ownerEmail
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.email"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.order.ownerEmail) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.order.ownerPhone
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.phoneNumber"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.order.ownerPhone) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("br"),
                _c("div", { staticClass: "playroomType" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "enums.propertyTypes." +
                            this.order.playroomPropertyType
                        )
                      ) +
                      " " +
                      _vm._s(
                        _vm.$tc(
                          "components.OrderDetailGeneralInformationPart.withRooms",
                          _vm.playroomRoomCount,
                          { count: _vm.playroomRoomCount }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("div", [_vm._v(" " + _vm._s(_vm.playroomFeatures) + " ")]),
                _c("br"),
                _c("div", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value:
                        "components.OrderDetailGeneralInformationPart.orderInformations",
                      expression:
                        "'components.OrderDetailGeneralInformationPart.orderInformations'"
                    }
                  ],
                  staticClass: "orderInformations"
                }),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "components.OrderDetailGeneralInformationPart.guestCount",
                          _vm.order.guestCount,
                          { count: _vm.order.guestCount }
                        )
                      ) +
                      ", "
                  ),
                  _vm.order.bookingType.toUpperCase() == "Hourly".toUpperCase()
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "components.OrderDetailGeneralInformationPart.hourCount",
                                Math.ceil(_vm.order.hourCount),
                                {
                                  count: _vm.order.hourCount.toLocaleString(
                                    "de"
                                  )
                                }
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.order.bookingType.toUpperCase() ==
                  "Overnight".toUpperCase()
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "components.OrderDetailGeneralInformationPart.dayCount",
                                _vm.order.dayCount,
                                { count: _vm.order.dayCount }
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { attrs: { "data-testid": "checkInCheckOut" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailGeneralInformationPart.checkIn"
                        )
                      ) +
                      " " +
                      _vm._s(_vm.formatDate(_vm.order.start)) +
                      " | " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailGeneralInformationPart.checkOut"
                        )
                      ) +
                      " " +
                      _vm._s(_vm.formatDate(_vm.order.end)) +
                      " "
                  )
                ]),
                _vm.order.customerName
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.name"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.order.customerName) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.order.customerEmail
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.email"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.order.customerEmail) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.order.customerPhone
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.phoneNumber"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.order.customerPhone) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("br"),
                _vm.showLastMinuteInfo
                  ? _c("div", { staticClass: "lastMinuteInfo" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.lastMinuteInfo"
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "GmapMap",
                    {
                      staticStyle: { width: "100%", height: "300px" },
                      attrs: {
                        center: {
                          lat: _vm.order.playroomLatitude,
                          lng: _vm.order.playroomLongitude
                        },
                        zoom: 11,
                        options: {
                          streetViewControl: false
                        }
                      }
                    },
                    [
                      !_vm.order.hiddenAddress
                        ? _c("GmapMarker", {
                            attrs: {
                              position: {
                                lat: _vm.order.playroomLatitude,
                                lng: _vm.order.playroomLongitude
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.order.hiddenAddress
                        ? _c("GmapCircle", {
                            attrs: {
                              center: {
                                lat: _vm.order.playroomLatitude,
                                lng: _vm.order.playroomLongitude
                              },
                              options: { strokeWeight: 0, fillOpacity: 0.2 },
                              radius: _vm.order.playroomAddressRadius * 1000
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
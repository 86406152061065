var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-to-host-modal" },
    [
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("components.MessageToHostModal.modalTitle", {
              name: _vm.playroom.ownerDisplayName
            }),
            color: "primary",
            centered: true,
            show: _vm.show
          },
          on: { "update:show": _vm.onClose },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "components.MessageToHostModal.cancel",
                        expression: "'components.MessageToHostModal.cancel'"
                      }
                    ],
                    attrs: { color: "light" },
                    on: {
                      click: function($event) {
                        return _vm.onClose()
                      }
                    }
                  }),
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "components.MessageToHostModal.send",
                        expression: "'components.MessageToHostModal.send'"
                      }
                    ],
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onSend()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "CForm",
            [
              _c("ValidatedInput", {
                attrs: {
                  value: _vm.$v.message.subject.$model,
                  field: _vm.$v.message.subject,
                  translationKey: "message.subject"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.message.subject, "$model", $event)
                  }
                }
              }),
              _c("ValidatedTextArea", {
                attrs: {
                  value: _vm.$v.message.body.$model,
                  field: _vm.$v.message.body,
                  translationKey: "message.body"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.message.body, "$model", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t("components.MessageToHostModal.success"),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }